<script setup lang="ts">
import { SliderDirections } from '@/types/slider-directions'

const slider = ref<HTMLElement | null>(null)

const isPrevButtonDisabled = ref(true)
const isNextButtonDisabled = ref(false)
const activeIndex = ref(0)
const {
  isScrolling: isSliderScrolling,
  arrivedState: sliderArrivedState,
  x: sliderPosition,
} = useScroll(slider)
const { width: sliderWidth } = useElementBounding(slider)

const props = withDefaults(
  defineProps<{
    // To scroll by entire width, set value to 0
    slidesToScroll?: number
    prevButtonClass?: string
    nextButtonClass?: string
    wrapperClass?: string
    showIndicators?: boolean
    indicatorActiveClass?: string
    indicatorInactiveClass?: string
    positionButtonsOutsideOfContainer?: boolean
    hidePrevButtonOnLoad?: boolean
  }>(),
  {
    slidesToScroll: 1,
    prevButtonClass: '',
    nextButtonClass: '',
    wrapperClass: '',
    showIndicators: false,
    indicatorActiveClass: '!bg-blue-100',
    indicatorInactiveClass: 'bg-blue-400',
    positionButtonsOutsideOfContainer: false,
    hidePrevButtonOnLoad: false,
  },
)

const hidePrevButton = ref(props.hidePrevButtonOnLoad)

watchDebounced(
  isSliderScrolling,
  () => {
    updateArrowStyles()
  },
  { debounce: 100 },
)

watchDebounced(
  sliderWidth,
  () => {
    updateArrowStyles()
  },
  { debounce: 100 },
)

const updateArrowStyles = () => {
  isPrevButtonDisabled.value = sliderArrivedState.left
  isNextButtonDisabled.value = sliderArrivedState.right
}

const getScrollingWidth = () => {
  if (!props.slidesToScroll || !slider.value) {
    return sliderWidth.value
  }

  const slide = slider.value.children[0]
  const slideWidth = slide?.getBoundingClientRect()?.width || 0

  return slideWidth * props.slidesToScroll
}

const updateActiveSlide = (directionMultiplier: number) => {
  const scrollingWidth = getScrollingWidth()

  sliderPosition.value += scrollingWidth * directionMultiplier
  activeIndex.value += directionMultiplier
  hidePrevButton.value = false
}

const isContentScrollable = computed(() => {
  return slider.value && slider.value.scrollWidth > sliderWidth.value
})

const getSlideCount = () => slider.value?.children.length

const onIndicatorClick = (index: number) => {
  updateActiveSlide(index - activeIndex.value)
}

const isIndicatorItemActive = (index: number) => {
  return activeIndex.value === index
}

let observer: IntersectionObserver

onMounted(() => {
  if (!slider.value) {
    return
  }

  const items = [...slider.value.children]
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const target = entry.target
          activeIndex.value = items.findIndex((el) => el.isSameNode(target))
        }
      })
    },
    {
      root: slider.value,
      threshold: 1,
    },
  )

  for (const element of items) {
    observer.observe(element)
  }
})

onBeforeUnmount(() => {
  observer?.disconnect()
})
</script>

<template>
  <div class="relative">
    <div class="flex items-center">
      <Button
        icon="pi pi-chevron-left"
        :class="[
          'p-button-rounded p-button-outlined hidden shrink-0 transition-opacity focus:shadow-none md:mr-4 md:block',
          {
            '!opacity-0': !isContentScrollable,
            '2xl:absolute 2xl:-left-12': positionButtonsOutsideOfContainer,
            'md:hidden': hidePrevButton,
          },
          prevButtonClass,
        ]"
        :disabled="!isContentScrollable || isPrevButtonDisabled"
        :aria-hidden="!isContentScrollable || isPrevButtonDisabled"
        aria-label="Previous slide"
        @click="updateActiveSlide(SliderDirections['prev'])"
      />
      <div
        ref="slider"
        :class="`slides flex w-full snap-x snap-mandatory flex-nowrap overflow-scroll scroll-smooth ${wrapperClass}`"
      >
        <slot />
      </div>
      <Button
        icon="pi pi-chevron-right"
        :class="[
          'p-button-rounded p-button-outlined hidden shrink-0 transition-opacity focus:shadow-none md:ml-4 md:block',
          {
            '!opacity-0': !isContentScrollable,
            '2xl:absolute 2xl:-right-12': positionButtonsOutsideOfContainer,
          },
          nextButtonClass,
        ]"
        :disabled="!isContentScrollable || isNextButtonDisabled"
        :aria-hidden="!isContentScrollable || isNextButtonDisabled"
        aria-label="Next slide"
        @click="updateActiveSlide(SliderDirections['next'])"
      />
    </div>
    <div
      v-if="showIndicators"
      class="relative top-4 flex h-2 justify-center space-x-2"
    >
      <template v-if="isContentScrollable">
        <Button
          v-for="(number, index) in getSlideCount()"
          :key="index"
          :class="[
            'flex h-2 w-2 rounded-full border-none p-0 focus:bg-blue-100 active:bg-blue-100 enabled:hover:bg-blue-100',
            isIndicatorItemActive(index)
              ? indicatorActiveClass
              : indicatorInactiveClass,
          ]"
          :aria-label="
            isIndicatorItemActive(index)
              ? `Active slider number ${number}`
              : `Go to slide number ${number}`
          "
          :disabled="isIndicatorItemActive(index)"
          @click="onIndicatorClick(index)"
        />
      </template>
    </div>
  </div>
</template>

<style scoped>
.slides::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

.slides {
  -ms-overflow-style: none; /* Edge */
  scrollbar-width: none; /* FF */
}

.p-button-icon-only :deep(.p-button-label) {
  display: none;
}
</style>
